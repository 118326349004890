import React from 'react';
import Contact from '../components/contact/Contact';

import SEO from '../components/layout/Seo';
import Layout from '../components/layout/Layout';

const ContactPage = () => (
  <Layout>
    <SEO title="Kontakt" />
    <Contact />
  </Layout>
);

export default ContactPage;
